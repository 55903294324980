<template>
    <div>
        <div class="row">
            <div class="col col-8">
            <div class="font-weight-bold">{{ item.user_type_name === 'legal_entity' ? item.company_name : `${item.first_name} ${item.last_name}` }}</div>
            </div>
            <div class="col col-4 p-0" v-if="!viewMode">
                <div class="card-action-group">
                    <div class="card-action" @click="$emit('edit', {item, index})">
                        <i class="fas fa-edit"></i>
                    </div>
                    <div class="card-action" @click="$emit('delete', {item, index})">
                        <i class="fas fa-trash"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="font-weight-bold mb-5">{{ userType }}</div>
        <div class="mb-5">
            <span class="font-weight-bold">{{ $t("BENEFICIARY.CARD.PERSONAL_NUMERIC_CODE") }}: </span>
            <span>{{ item.personal_numeric_code}}</span>
        </div>
        <div class="mb-5">
            <div class="font-weight-bold">{{ $t("BENEFICIARY.CARD.ADDRESS") }}:</div>
            <div>{{ addressComputed }}</div>
        </div>
        <div class="row mb-5">
            <div class="col col-6">
                <div class="font-weight-bold">{{ $t("BENEFICIARY.CARD.CONTACT") }}:</div>
                <div>{{ item.phone_number }}</div>
            </div>
            <div class="col col-6">
                <div class="font-weight-bold">{{ $t("BENEFICIARY.CARD.CITIZENSHIP") }}:</div>
                <div>{{ citizenship }}</div>
            </div>
        </div>
        <div>{{ item.email }}</div>
    </div>
</template>
<script>
export default {
    name: 'BeneficiaryItemCard',
    props: {
        item: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        viewMode: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        addressComputed() {
            let address = '';
            if (this.item.county) address += `${this.$t('BENEFICIARY.CARD.ADDRESS_COUNTY')} ${this.item.county}`;
            if (this.item.country) address += this.item.country;
            if (this.item.locality) address +=  `, ${this.item.locality}`;
            if (this.item.street) address += `, ${this.$t('BENEFICIARY.CARD.ADDRESS_STREET')} ${this.item.street}`;
            if (this.item.street_number) address += `, ${this.$t('BENEFICIARY.CARD.ADDRESS_NUMBER')} ${this.item.street_number}`;
            if (this.item.staircase) address += `, ${this.$t('BENEFICIARY.CARD.ADDRESS_STAIRCASE')} ${this.item.staircase}`;
            if (this.item.apartment) address += `, ${this.$t('BENEFICIARY.CARD.ADDRESS_APARTMENT')} ${this.item.apartment}`;
            return address;
        },
        userType() {
            return this.item?.user_type_name === "legal_entity"
                ? this.$t(
                        "BENEFICIARY.BENEFICIARY_DETAILS.PERSON.LEGAL"
                )
                : this.$t(
                        "BENEFICIARY.BENEFICIARY_DETAILS.PERSON.INDIVIDUAL"
                );
        },
        citizenship() {
            if (this.item.user_type_name === 'foreigner') {
                return this.$t("BENEFICIARY.BENEFICIARY_DETAILS.CITIZENSHIP.FOREIGNER")
            }
            return this.$t("BENEFICIARY.BENEFICIARY_DETAILS.CITIZENSHIP.ROMANIAN");
        }
    }
}
</script>
<style lang="">
    
</style>